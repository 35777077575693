<script>

import FveText from "@widgetFormValidate/src/Element/Text/FveText";

export default {
  mixins: [
    FveText
  ],
  methods: {
    validateFunction(str) {
      if(str.length < 3 ){ return 'Длинна логина не менее 3 символов'; }
      return 'SUCCESS';
    },
  }
};
</script>
