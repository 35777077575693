<template>
  <form class="form-auth form-base row" @submit="formSubmit"  @submit.prevent="formSubmit">
    <div class="col-12">
      <FveLogin
        label="Логин"
        name="login"
        required
        v-model="form.login"
        @keypress-enter="formSubmit"
      />
    </div>
    <div class="col-12">
      <FvePassword
        label="Пароль"
        name="password"
        required
        v-model="form.password"
        @keypress-enter="formSubmit"
      />
    </div>
  </form>
</template>

<script>

import FveLogin from "@widgetFormValidate/src/Element/Text/FveLogin";
import FvePassword from "@widgetFormValidate/src/Element/Text/FvePassword";
//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";

export default {
  mixins: [
    FveFormMixin
  ],
  components: {
    FveLogin,
    FvePassword,
  },
  methods: {
    formSchema() {
      return {
        login: {
          type: String,
          default: () => { return ''; }
        },
        password: {
          type: String,
          default: () => { return ''; }
        },
      };
    },
  }

};

</script>

<style lang="scss" scoped>
</style>
