<template>
  <div class="auth">
    <h1 class="auth__title">Войдите, чтобы начать работу</h1>

    <LoginForm ref="loginForm"/>
    
    <div class="auth__btn">
      <b-button block variant="danger" size="lg" @click="auth">Войти</b-button>
      <router-link :to="{ name: this.$routeName.PASSWORD_RESET }" class="auth__link">Забыли пароль?</router-link>
    </div>
    
  </div>
</template>

<script>

import LoginForm from "@component/Form/LoginForm";

export default {
  name: "Authorization",
  components: {
    LoginForm,
  },
  methods: {
    async auth() {

      let loginFormData = this.$refs.loginForm.formSubmitGetData();
      if(!loginFormData) { return; }

      RequestManager.Auth.authorization({
        username: loginFormData.login,
        password: loginFormData.password,
      }).then( (res) => {
        if(!res.token) {
          this.$dialogs.alert('Не удалось авторизоваться', {title: 'Ошибка', size: 'sm'});
        }

        this.$store.commit('user/setToken', res.token);
  
        RequestManager.Admin.getProfileMe({}).then((data) => {
          let role = '';
          if(data.system_role === 'admin') {
            role = 'ROLE_SUPER_ADMIN';
          }
          this.$store.commit('user/setRole', role);
        });

        this.$nextTick(() => {
          this.$router.push({name: this.$routeName.DASHBOARD});
        });

      });

    }
  }
};
</script>

<style lang="scss" scoped>

// auth
.auth {
  width: 100%;
  max-width: 442px;
  .auth__title {
    margin-bottom: 26px;
    font-size: 25px;
  }
  .auth__btn {
    display: flex;
    align-items: center;
    margin-top: 10px;
    text-align: center;
    .btn {
      max-width: 200px;
    }
    .auth__link {
      margin-left: 20px;
      color: var(--color-sub);
      font-weight: 500;
      font-size: 17px;
      &:hover {
        color: var(--color-sub-active);
      }
    }
  }
}
</style>
